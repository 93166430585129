<template>
  <li class="mb3 mt3 tsk tli">
    <div class="isFlex isFlexAlign">
      <div v-if="changeCompleted && canEdit"><b-checkbox v-model="task.completed" @input="saveTask()"></b-checkbox></div>
      <div v-else><b-checkbox disabled></b-checkbox></div>
      <!-- <div>
        <div class="pie">
          <div class="pie__segment" data-label="CSS 60%" style="--offset: 0; --value: 10; --bg: #33b9ff; over50: 1;"></div>
        </div>
      </div> -->
      <div class="isFlexGrow isPointer" :class="{ 'archived': task.deleted, 'completed': task.completed }">
        <div class="txtbold" @click="editTask()" v-if="!task.expanded">
          <div>
            <div>{{task.tasktext}}</div>
          </div>
        </div>
        <div class="txtbold" v-if="task.expanded">
          <div>
            <div class="isFlex">
              <div class="isFlexGrow"><b-input v-model="task.tasktext" @keyup.native.enter="saveTask();" id="tedit"></b-input></div>
              <div class="ml7 mr7"><b-button icon-left="times" @click="cancelEdit()"></b-button></div>
            </div>
          </div>
        </div>
        <div class="isFlex" v-if="task.expanded">
          <div></div>
          <div class="pt3 isFlex">
            <div class="mr3"><b-button size="is-small" icon-left="plus" @click="addSibling()">Add Sibling</b-button></div>
            <div class="mr3"><b-button size="is-small" icon-left="child" @click="addChild()">Add child</b-button></div>
            <b-button class="mr3" size="is-small" @click="taskRestore()" v-if="task.deleted">Restore</b-button>
            <b-button class="mr3" size="is-small" @click="taskArchive()" v-if="!task.deleted">Archive</b-button>
          </div>
        </div>

        <div>
          <div></div>
        </div>
      </div>
      <div v-if="task.expanded">
        <div class="iconbutton" @click="moveRowUp(idx)"><font-awesome-icon :icon="['fal', 'arrow-alt-up']" size="lg"></font-awesome-icon></div>
        <div class="iconbutton" @click="moveRowDown(idx)"><font-awesome-icon :icon="['fal', 'arrow-alt-down']" size="lg"></font-awesome-icon></div>
      </div>
      <div v-if="!task.expanded">
        <div class="ml7 mr7"><b-button icon-left="eye" type="is-light" @click="taskOpenList()"></b-button></div>
      </div>
    </div>
    
    <ul class="tul" v-if="task.steps.length !== 0">
      <TaskItem v-for="(task, index) in task.steps" :key="index" :idx="index" :item="task" :isDemo="isDemo" :canEdit="canEdit"></TaskItem>
    </ul>
  </li>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import ModalTask from "@/modals/ModalTask.vue";

export default {
  name: 'TaskItem',
  mixins: [Utils],
  // components: { TaskItem },
  props: {
    item: Object,
    idx: Number,
    isDemo: Boolean,
    canEdit: Boolean,
    Active: { type: String, default: '' },
  },
  data: function () {
    return {
      task: this.item,
      isEdit: false,
      changeCompleted: true,
    }
  },
  created() {
    // this.task.expanded = false;
    this.processTask();
  },
  methods: {
    processTask() {
      this.changeCompleted = true;
      if (this.task.steps.length !== 0) {
        for (const ts of this.task.steps) {
          if (!ts.completed) {
            this.changeCompleted = false;
          }
        }
      }
    },
    editTask() {
       // @click="(task.expanded) ? task.expanded=false:task.expanded=true"
      if(!this.canEdit) { return; }
      if (this.task.goalid === '') {
        this.task.expanded = true;
        // this.$refs.tedit.focus();
        // setTimeout(() => {
        //   document.querySelector(`[id="tedit"]`).focus();
        // }, 0);
      } else {
        this.task.expanded = true;
        // this.$refs.tedit.focus();
        // setTimeout(() => {
        //   document.querySelector(`[id="tedit"]`).focus();
        // }, 0);
      }
    },
    cancelEdit() {
      this.task.expanded = false;
      setTimeout(() => {
        // do something
      }, 0);
      this.$buefy.toast.open({ message: `Task closed`, position: "is-bottom", type: "is-info", duration: 10 });
    },
    saveTask() {
      if (this.task.completed) {
        this.task.workflow = 'Completed';
      } else {
        if (this.task.workflow === 'Completed') {
          this.task.workflow = 'New';
        }
      }
      const data = {
        _id: this.task._id,
        tasktext: this.task.tasktext,
        taskdescription: this.task.taskdescription,
        completed: this.task.completed,
        workflow: this.task.workflow
      }
      const upt = this.DataSend('post', '/task/updatefew', data);
      this.task.expanded = false;
      this.$parent.processTask();
    },

    moveRowUp(i) {
      if (i === 0) { return }
      let newpos = i - 1;
      this.$parent.moveEachPre(i, newpos);
      // this.doSave();
    },

    async addChild() {
      this.$buefy.dialog.prompt({
        message: `Create a child task of this task.`,
        inputAttrs: {
          placeholder: "Describe task...",
          // maxlength: 10
        },
        trapFocus: true,
        onConfirm: async value => {
          const newtask = {
          _id: "new",
            goalid: this.task.goalid,
            goalcolor: this.task.goalcolor,
            goalname: this.task.goaltitle,
            parentid: this.task._id,
            tasktext: value,
            assignedto: [],
          };
          const newtasksend = await this.DataSend('put', '/task', newtask, 'rows');
          this.task.steps.push(newtasksend);
        }
      });
    },

    async addSibling() {
      this.$buefy.dialog.prompt({
        message: `Create a sibling task of this task.`,
        inputAttrs: {
          placeholder: "Describe task...",
          // maxlength: 10
        },
        trapFocus: true,
        onConfirm: async value => {
          const newtask = {
          _id: "new",
            goalid: this.task.goalid,
            goalcolor: this.task.goalcolor,
            goalname: this.task.goaltitle,
            parentid: this.task.parentid,
            tasktext: value,
            assignedto: [],
          };
          const newtasksend = await this.DataSend('put', '/task', newtask, 'rows');
          this.$parent.task.steps.push(newtasksend);
        }
      });
    },

    taskArchive() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Archive</b> this task and all it's child tasks?",
        confirmText: "Archive",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          if(this.isDemo) {
            this.DataDemo();
            return;
          }
          this.task.deleted = true;
          const arctask = await this.DataDelete(`/task/archive/${this.task._id}`, "row");
          this.task.expanded = false;
        }
      });
    },
    taskRestore() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Restore</b> this task and all it's children tasks?",
        confirmText: "Restore",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          if(this.isDemo) {
            this.DataDemo();
            return;
          }
          this.task.deleted = false;
          const restask = await this.DataDelete(`/task/restore/${this.task._id}`, "row");
          this.task.expanded = false;
        }
      });
    },

    moveRowDown(i) {
      const total = this.$parent.movePreTotals();
      if (i < total - 1) { 
        let newpos = i + 1;
        this.$parent.moveEachPre(i, newpos);
      }
      // this.doSave();
    },

    movePreTotals() {
      const instl = this.task.steps.length;
      return instl;
    },
    moveEachPre(oldidx, newidx) {
      const newarr = [...this.task.steps];
      this.task.steps = [];
      this.moveEach(newarr, oldidx, newidx);
      setTimeout(() => {
        for (const t of newarr) {
          this.task.steps.push(t);
        }
      }, 0);
    },

    async taskOpenList() {
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) { useFull = false; useCanCancel = true; }
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalTask,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: this.task, // this is the row data that gets sent to the modal
          callBus: "Modal_Goals_Task" // name of the listner for the data coming in from the modal
        },
        events: {
          taskUpdated: value => {
            task.completed = value.data.completed;
          }
        }
      });
    },

  },
  
}
</script>

<style>
.tul {
  /* list-style-type: circle; */
  padding-left: 0px;
}
.tli {
  background-color: rgba(0, 0, 0, 0.05);
}
.archived {
  text-decoration: line-through;
}

.pie {
  border-radius: 100%;
  background-color: #e6f6ff;
  height: calc(var(--size, 20) * 1px);
  overflow: hidden;
  position: relative;
  width: calc(var(--size, 20) * 1px);
}
.pie__segment {
  --a: calc(var(--over50, 0) * -100%);
  --b: calc((1 + var(--over50, 0)) * 100%);
  --degrees: calc((var(--offset, 0) / 100) * 360);
  -webkit-clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
  clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
  height: 100%;
  position: absolute;
  -webkit-transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
          transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  width: 100%;
  z-index: calc(1 + var(--over50));
}
.pie__segment:after,
.pie__segment:before {
  background: var(--bg, #e74c3c);
  content: '';
  height: 100%;
  position: absolute;
  width: 100%;
}
.pie__segment:before {
  --degrees: calc((var(--value, 45) / 100) * 360);
  -webkit-transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
          transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
}
.pie__segment:after {
  opacity: var(--over50, 0);
}
* {
  box-sizing: border-box;
}
</style>
