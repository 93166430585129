<template>
  <div class="contentwrapper">
      <b-loading is-full-page :active.sync="isLoading" :can-cancel="false"></b-loading>
      <div class="taskHeader">
        <div class="taskSearchSel">
          <b-select v-model="ActiveGoalId" icon="bullseye-arrow" @input="setActiveGoal();getTasks()" expanded>
            <option v-for="(g, gi) in GoalList" :key="gi" :value="g._id">{{g.goaltitle}}</option>
          </b-select>
        </div>
        <div class="taskSearchCol">
          <b-input placeholder="Search" type="search" icon="search" icon-right="filter" v-model="searchField" @input="doSearch()" ></b-input>
        </div>
      </div>
      <div class="taskHeader p3" v-if="GoalId === ''">
        <div><b-checkbox v-model="Completed">Show completed</b-checkbox></div>
      </div>
      <div class="isFlexGrow" style="position: relative;">
        <div class="contentsroll p11" id="taskcontent">
          <!-- content: start -->
          <ul class="tul">
            <TaskItem v-for="(task, index) in TaskList" :key="index" :idx="index" :item="task" :isDemo="isDemo" :canEdit="canEdit"></TaskItem>
          </ul>
          <!-- content: end -->
        </div>
      </div>
      <div class="contentfooter isFlex vlgreybg p7">
        <div class="ml7 mr7"><b-button icon-left="sync-alt" @click="getTasks"></b-button></div>
        <div class="isFlexGrow"><b-input v-model="newtask" @keyup.native.enter="addNewTask();" id="tedit"></b-input></div>
        <div class="ml7 mr7"><b-button icon-left="plus" @click="addNewTask"></b-button></div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';
import debounce from "lodash/debounce";
import array from "lodash/array";
import TaskItem from "@/components/TaskItem.vue";

export default {
  mixins: [Utils],
  name: 'Tasks',
  components: { TaskItem },
  data() {
    return {
      isLoading: true,
      searchField: '',
      GoalList: [],
      TaskList: [],
      GoalId: '',
      Completed: false,
      canEdit: false,
      canArc: false,
      isDemo: false,
      newtask: '',
      scrollpos: 0,
      scrollposfin: 0,
    }
  },
  created() {
    this.getGoalSelect();
    this.getTasks()
  },
  methods: {
    doSearch: debounce(function() {
      this.getTasks();
    }, 2000),

    async getTasks() {
      this.isLoading = true;
      this.TaskList = [];
      let usesearch = 'plain';
      if (this.ActiveGoalId !== '') {
        usesearch = 'tree';
      } else if (this.ActiveGoalId !== '' && this.searchField !== '') {
        usesearch = 'plain';
      }
      if (this.ActiveGoalId !== '') { 
        const gp = await this.DataGet(`/goalprivs/${this.ActiveGoalId}`);
        this.canEdit = gp.canEdit;
        this.isDemo = gp.isDemo;
      }
      if (this.ActiveGoalId === '') { 
        this.canEdit = true;
      }
      if (usesearch === 'plain') {
        const search = {
          completed: this.Completed,
          searchField: this.searchField,
          goalid: this.ActiveGoalId
        }
        this.TaskList = await this.DataSend('post', '/tasks/search', search, 'rows', false);
      } else {
        const data = {
          parentid: this.ActiveGoalId,
          parenttype: "goal",
          deleted: false,
          workflowtype: "ne",
          workflow: "Completed",
          sortby: "childorder",
        };
        const tasks = await this.DataSend("post", "/taskstree", data, "", false);
        this.TaskList = tasks.rows;
      }
      this.isLoading = false;
    },
    movePreTotals() {
      return this.TaskList.length;
    },
    moveEachPre(oldidx, newidx) {
      var objDiv = document.getElementById("taskcontent");
      this.scrollposfin = objDiv.scrollTop;
      const newarr = [...this.TaskList];
      this.TaskList = [];
      this.moveEach(newarr, oldidx, newidx);
      setTimeout(() => {
        for (const t of newarr) {
          this.TaskList.push(t);
        }
        this.tasksSort();

        setTimeout(() => {
          var objDiv = document.getElementById("taskcontent");
          objDiv.scrollTop = this.scrollposfin;
        }, 0);
      }, 10);
    },

    tasksSort: debounce(function() {
      this.doSort();
    }, 2000),

    doSort() {
      this.DataSend('post', '/tasks/sort', this.TaskList, '', false);
    },

    async addNewTask() {
      if (this.newtask === '') { return; }
      let goalcolor = '';
      let goalname = '';
      const hasrecord = array.findLastIndex(this.GoalList, { '_id': this.GoalId });
      if (this.GoalId !== '') {
        goalcolor = this.GoalList[hasrecord].goalcolor;
        goalname = this.GoalList[hasrecord].goaltitle;
      }
      const newtaskin = {
        _id: "new",
        goalid: this.GoalId,
        goalcolor,
        goalname,
        parentid: '',
        tasktext: this.newtask,
        assignedto: [],
      };
      const newtasksend = await this.DataSend('put', '/task', newtaskin, 'rows', false);
      this.TaskList.push(newtasksend);
      this.newtask = '';
      var objDiv = document.getElementById("taskcontent");
      let scrollHeightIn = objDiv.scrollHeight + 500;
      objDiv.scrollTop = scrollHeightIn;
    },

    processTask() {
      
    }
  },
  mounted() {
    var element = document.getElementById("taskcontent");
    element.addEventListener("scroll", function(e){
      this.scrollpos = e.srcElement.scrollTop;
    });
  },
}
</script>

<style>
  .taskSearchSel {
    display: inline-block;
    padding: 3px;
    width: 100%;
  }
  .taskSearchCol {
    display: inline-block;
    padding: 3px;
    width: 100%;
  }
  .taskHeader {
    background-color: #e8e8f0;
  }
  @media (min-width: 900px){
  .taskSearchSel {
    width: 30%;
  }
  .taskSearchCol {
    width: 70%;
  }
}
</style>